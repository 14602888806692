<template>
  <div class="com-header my-header flex">
    <div class="h-left flex1"></div>
    <div class="h-center">
      {{ title }}
    </div>
    <div class="h-right flex1">
      {{ nowDate }}&nbsp;&nbsp;{{ nowWeek }}&nbsp;&nbsp;{{ nowTime }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      // default: '铁塔智算（苏州）中心'
    },
  },
  data() {
    return {
      nowDate: "", // 当前日期
      nowTime: "", // 当前时间
      nowWeek: "", // 当前星期
    };
  },
  mounted() {
    // 时间
    this.getDate();
  },
  methods: {
    // 时间
    getDate: function () {
      let _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let week = new Date().getDay();
      let hh = `${
        (new Date().getHours() < 10 ? "0" : "") + new Date().getHours()
      }`;
      let mf = `${
        (new Date().getMinutes() < 10 ? "0" : "") + new Date().getMinutes()
      }`;
      if (week == 1) {
        this.nowWeek = "星期一";
      } else if (week == 2) {
        this.nowWeek = "星期二";
      } else if (week == 3) {
        this.nowWeek = "星期三";
      } else if (week == 4) {
        this.nowWeek = "星期四";
      } else if (week == 5) {
        this.nowWeek = "星期五";
      } else if (week == 6) {
        this.nowWeek = "星期六";
      } else {
        this.nowWeek = "星期日";
      }
      _this.nowTime = `${hh > 12 ? "下午" : "上午"}` + hh + ":" + mf;
      _this.nowDate = yy + "年" + mm + "月" + dd + "日";
      // _this.nowDate = yy + "-" + mm + "-" + dd + "-";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./comm.scss";
.my-header {
  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-bottom: 36px;
    box-sizing: border-box;
    font-size: $FontSize32;
  }
  .h-left {
  }
  .h-center {
    width: 950px;
    font-size: $FontSize60;
    font-weight: bold;
  }
}
</style>