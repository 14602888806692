<template>
  <div class="com-footer my-footer flex">
    <div class="h-center">
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    
  },
  data() {
    return {
     
    };
  },
  mounted() {
  },
  methods: {
 
  },
};
</script>

<style lang="scss" scoped>
@import "./comm.scss";
.my-footer {
  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-bottom: 36px;
    box-sizing: border-box;
    font-size: $FontSize32;
  }
 
}
</style>