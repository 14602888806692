<template>
  <div>
    <div class="top"></div>
    <div class="bottom">
      <div class="bottom-left"></div>
      <div class="bottom-right">
        <div class="notice-box">
          <!-- <div class="notice-box-title">需求响应</div> -->
          <div
            class="notice-box-cell DS-Digital-Bold"
            v-for="(item, i) in target"
            :key="'target' + i"
          >
            {{ item.label }} <br />
            {{ item.number }} <span class="unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {},
  data() {
    let mIcon = require("@/assets/images/exposition/juheshang.png");
    return {
      photovoltaic: {},
      now: "日",
      target: [
        {
          icon: mIcon,
          label: "参与次数",
          number: "",
          unit: "个",
        },
        {
          icon: mIcon,
          label: "累计响应",
          number: "",
          unit: "个",
        },
        {
          icon: mIcon,
          label: "综合单价",
          number: "",
          unit: "条",
        },
        {
          icon: mIcon,
          label: "指令成功率",
          number: "",
          unit: "%",
        },
        {
          icon: mIcon,
          label: "有效调节率",
          number: "",
          unit: "万元",
        },
      ],
      province: "32",
      show1: false,
      show2: false,
    };
  },
  destroyed() {
    this.$bus.$off("updateDummyFn");
  },
  mounted() {
    this.$bus.$on("updateDummyFn", () => {
      this.getphotovoltaic();
    //   this.getData();
    });
    this.getphotovoltaic();
    // this.getData();
  },
  methods: {
    nowType() {
      return localStorage.getItem("typeTime") || "日";
    },
    getphotovoltaic() {
      this.show1 = false;
      let params = {
        enCode: "powerplant-mid-xuqiuxiangying",
        "@province": localStorage.getItem("updateDummyPcode")
          ? localStorage.getItem("updateDummyPcode")
          : this.province,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "响应主体分布");
          let data = res[0] || {};
          this.target[0].number = data.invitation_cnts || 0;
          this.target[1].number = data.associated_cnts || 0;
          this.target[2].number = data.executive_instruction_delivery || 0;
          this.target[3].number = data.effective_annual_node_rate || 0;
          this.target[4].number = data.cumulative_subsidy || 0;
          this.show1 = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // getData() {
    //   this.show2 = false;
    //   let params = {
    //     enCode: "powerplant-mid-fuzhufuwu",
    //     "@province": localStorage.getItem("updateDummyPcode")
    //       ? localStorage.getItem("updateDummyPcode")
    //       : this.province,
    //   };
    //   dataInterface
    //     .dataFaceApi(params)
    //     .then((res) => {
    //       console.log(res, "响应主体分布111111111111111111111");
    //       console.log(this.target1, "响应主体分布111111111111111111111");
    //       let data = res[0] || {};
    //       this.target1[0].number = data.invitation_cnts || 0;
    //       this.target1[1].number = data.associated_cnts || 0;
    //       this.target1[2].number = data.executive_instruction_delivery || 0;
    //       this.target1[3].number = data.effective_annual_node_rate || 0;
    //       this.target1[4].number = data.cumulative_subsidy || 0;
    //       this.show2 = true;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "DS-Digital-Bold";
  src: url("~@/assets/font/DS-Digital-Bold.ttf");
}
.DS-Digital-Bold {
  font-family: "DS-Digital-Bold";
}
.top {
  height: 114px;
  width: 100%;
  background-color: rgb(180, 238, 86);
}
.bottom {
  height: 656px;
  width: 100%;
  background-color: rgb(241, 147, 116);
  display: flex;
  .bottom-left {
    width: 924px;
    height: 656px;
    background-color: rgb(98, 209, 135);
  }
  .bottom-right {
    width: 1364px;
    height: 656px;
    background-color: rgb(114, 144, 158);
    .notice-box {
      width: 1364px;
      // width: 100%;
      height: 129px;
      margin-bottom: 30px;
      //   margin-left: 70px;
      background: url("~@/assets/images/exposition/dummyNotice.png") no-repeat;
      background-size: 100% 100%;
      font-size: $FontSize36;
      padding-left: 160px;
      display: flex;

      .notice-box-title {
        width: 190px;
        line-height: 129px;
        // background-color: pink;
        text-align: center;
        padding-right: 10px;
      }

      .notice-box-cell {
        width: 270px;
        // text-align: center;
        font-size: $FontSize32;
        margin-top: 20px;
        padding: 0 10px 0 40px;
        background: url("~@/assets/images/exposition/link.png") no-repeat;
        background-size: 23px 45px;
        background-position: 20px 8px;
      }
    }
  }
}
</style>

