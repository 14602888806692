<template>
  <div class="screen-wrapper1">
    <div style="height: 80px; width: 100%" v-if="fromUser == 'guizhou'"></div>
    <div class="screen screenBg" id="screen">
      <div class="demo-root">
        <header>
          <myHeader> </myHeader>
        </header>

        <main>
          <div class="demo-left">
            <!-- <div class="demo-left-top"></div> -->
            <div class="demo-left-bottom"></div>
          </div>
          <div class="demo-right">
               <div class="demo-right-top"></div>
            <div class="demo-right-bottom">
                <!-- 1111 -->
                <xunidianchangRightBottom></xunidianchangRightBottom>
            </div>
          </div>
        </main>
        <footer>
            <myFooter></myFooter>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import myHeader from "./components/myHeader1.vue";
import myFooter from "./components/myFooter.vue";
import xunidianchangRightBottom from "./components/xunidianchangRightBottom.vue";
export default {
  components: {
    myHeader,
    xunidianchangRightBottom,
    myFooter
  },
  data() {
    return {
      pageInterval: null,
      intervalNum: 180,
      intervalNumSet: 180,
      fromUser: "",
    };
  },
  beforeCreate() {
    console.log("this.$route====333=======", this.$route.query.fromScreen);
    if (this.$route.query.fromScreen) {
      sessionStorage.setItem("fromScreen", this.$route.query.fromScreen);
    }
    localStorage.setItem("harbour_id", "76df7579ccc24d2693f6488c38a3a0b0");
    let fromPath = localStorage.getItem("newBigScreen::fromPath");
    let playCount = parseInt(
      localStorage.getItem("newBigScreen::playCount") || 0,
      10
    );
    if (fromPath && ++playCount >= 2) {
      localStorage.setItem("newBigScreen::playCount", "0");
      localStorage.setItem("newBigScreen::fromPath", "");
      location.reload();
    } else {
      localStorage.setItem("newBigScreen::playCount", playCount + "");
    }
  },
  mounted() {
    document.title = "虚拟电厂SASS管控平台监控";
    let { fromUser, scaleY } = this.$route.query;
    fromUser ? sessionStorage.setItem("fromUser", fromUser) : "";
    scaleY ? localStorage.setItem("newBigScreen::scaleY", scaleY) : "";

    let that = this;
    this.fromUser = sessionStorage.getItem("fromUser");
    // document.title = "移动能源光储充换车云一体化大屏";
    //初始化自适应  ----在刚显示的时候就开始适配一次
    that.handleScreenAuto();
    //绑定自适应函数   ---防止浏览器栏变化后不再适配
    window.onresize = function () {
      that.handleScreenAuto();
    };
  },
  deleted() {
    window.onresize = null;
  },
  methods: {
    //数据大屏自适应函数
    handleScreenAuto() {
      const designDraftWidth = 4576; //设计稿的宽度
      const designDraftHeight = 2184; //设计稿的高度
      //根据屏幕的变化适配的比例
      const scale =
        document.documentElement.clientWidth /
          document.documentElement.clientHeight <
        designDraftWidth / designDraftHeight
          ? document.documentElement.clientWidth / designDraftWidth
          : document.documentElement.clientHeight / designDraftHeight;
      //缩放比例

      console.log(scale);

      document.querySelector(
        "#screen"
      ).style.transform = `scale(${scale}) translate(-50%)`;
      // let scaleY = parseFloat(localStorage.getItem('newBigScreen::scaleY')) || 0.98
      // if (sessionStorage.getItem('fromUser') == 'guizhou') {
      // 	document.querySelector('#screen').style.transform = `scale(${scale}, ${scale * 1.6}) translate(-50%)`;
      // } else {
      // 	document.querySelector('#screen').style.transform =
      // 		`scale(${scale}, ${scale * scaleY}) translate(-50%)`;
      // }
    },
  },
  destroyed() {
    clearInterval(this.pageInterval);
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/scss/pc.scss";
@import "@/assets/scss/main-map.scss";

@font-face {
  font-family: "DS-Digital-Bold";
  src: url("~@/assets/font/DS-Digital-Bold.ttf");
}

.screen-wrapper1 {
  height: 100%;
  width: 100%;
  font-size: 20px;
  background-color: #000000;
  color: #ffffff;
  .DS-Digital-Bold {
    font-family: "DS-Digital-Bold";
  }

  .screenBg {
    // background-image: url("~@/assets/images/exposition/senceBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .screen {
    overflow: hidden;
    display: inline-block;
    width: 4576px;
    height: 2184px;
    transform-origin: 0 0;
    position: absolute;
    left: 50%;
    // background-color: yellow;

    .demo-root {
      header {
        width: 4576px;
        height: 245px;
        background-color: pink;
        text-align: center;
      }

      main {
        height: 1829px;
        display: flex;
        // background-color: blue;

        .demo-left {
          width: 2288px;
          height: 1829px;
        //   display: flex;
          background-color: green;
          position: relative;
        //   .demo-left-top{
        //       height: 1433px;
        //       width: 2191px;
        //       background-color: goldenrod;
        //   }
           .demo-left-bottom{
              position:absolute;
              height: 506px;
              width: 2288px;
              bottom: 0px;
              left: 0px;
              background-color: rgb(149, 183, 233);
          }
        }

        .demo-right {
          width: 2288px;
        //   display: flex;
          height: 1829px;
          background-color: gray;
          .demo-right-top{
              height: 1059px;
              width: 2288px;
              background-color: rgb(163, 230, 178);
          }
           .demo-right-bottom{
              height:770px;
              width: 2288px;
              background-color: rgb(241, 178, 226);
          }
        }
      }

      footer {
        width: 100%;
        height: 110px;
        font-size: 40px;
        text-align: center;
        line-height: 110px;
        background: rgba(122, 194, 204, 0.3);
      }
    }
  }
}
</style>